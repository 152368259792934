"use client";
import { Toaster } from "react-hot-toast";

const ToastProvider = () => {
  return ( 
    <Toaster />
  );
}
 
export default ToastProvider;
